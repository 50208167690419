import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [2,3,4,8,9];

export const dictionary = {
		"/(auth)/(app)": [11,[2,3]],
		"/(auth)/(app)/account": [22,[2,3]],
		"/(no-auth)/(background-hue)/account/login": [~55,[10]],
		"/(no-auth)/(background-hue)/account/reset-password": [56,[10]],
		"/(no-auth)/(background-hue)/account/reset-password/confirm": [57,[10]],
		"/(no-auth)/(background-hue)/account/verify-email": [58,[10]],
		"/(auth)/(app)/(admin)/admin/users": [12,[2,3,4]],
		"/(auth)/(app)/(admin)/data/loinc": [13,[2,3,4]],
		"/(auth)/(app)/(admin)/data/sam": [14,[2,3,4]],
		"/(auth)/(app)/(admin)/data/snomed-ct": [15,[2,3,4]],
		"/(no-auth)/demo": [60],
		"/(auth)/(demo)/demo/[id]": [52,[2,8]],
		"/(auth)/(app)/(admin)/evals": [16,[2,3,4]],
		"/(auth)/(app)/(admin)/evals/runs": [20,[2,3,4]],
		"/(auth)/(app)/(admin)/evals/runs/[run_uuid]": [21,[2,3,4]],
		"/(auth)/(app)/(admin)/evals/[uuid]": [17,[2,3,4,5]],
		"/(auth)/(app)/(admin)/evals/[uuid]/expected-output": [18,[2,3,4,5]],
		"/(auth)/(app)/(admin)/evals/[uuid]/runs/[run_uuid]": [19,[2,3,4,5]],
		"/(auth)/(app)/kmehr/[id]": [23,[2,3]],
		"/(no-auth)/onboarding": [61],
		"/(auth)/(onboarding)/onboarding/ingesting-welcome": [53,[2,9]],
		"/(auth)/(onboarding)/onboarding/upload-patients": [54,[2,9]],
		"/(auth)/(app)/patients": [24,[2,3]],
		"/(auth)/(app)/patients/[id]": [25,[2,3,6]],
		"/(auth)/(app)/patients/[id]/carepaths": [26,[2,3,6]],
		"/(auth)/(app)/patients/[id]/carers": [27,[2,3,6]],
		"/(auth)/(app)/patients/[id]/contactreports": [28,[2,3,6]],
		"/(auth)/(app)/patients/[id]/diagnosis": [29,[2,3,6]],
		"/(auth)/(app)/patients/[id]/documents": [30,[2,3,6]],
		"/(auth)/(app)/patients/[id]/documents/[uuid]": [31,[2,3,6]],
		"/(auth)/(app)/patients/[id]/family": [32,[2,3,6]],
		"/(auth)/(app)/patients/[id]/incapacities": [33,[2,3,6]],
		"/(auth)/(app)/patients/[id]/kmehr": [34,[2,3,6]],
		"/(auth)/(app)/patients/[id]/kmehr/[transaction_id]": [35,[2,3,6]],
		"/(auth)/(app)/patients/[id]/medicationlist": [37,[2,3,6]],
		"/(auth)/(app)/patients/[id]/medication": [36,[2,3,6]],
		"/(auth)/(app)/patients/[id]/parameters": [38,[2,3,6]],
		"/(auth)/(app)/patients/[id]/search": [39,[2,3,6]],
		"/(auth)/(app)/patients/[id]/tests": [40,[2,3,6]],
		"/(auth)/(app)/patients/[id]/timelineconsults": [41,[2,3,6]],
		"/(auth)/(app)/patients/[id]/vaccinations": [42,[2,3,6]],
		"/(auth)/(app)/populations": [43,[2,3]],
		"/(auth)/(app)/populations/carepaths": [~44,[2,3,7]],
		"/(auth)/(app)/populations/carepaths/diabetes": [45,[2,3,7]],
		"/(auth)/(app)/populations/carepaths/kidneys": [46,[2,3,7]],
		"/(auth)/(app)/populations/contactreports": [47,[2,3]],
		"/(auth)/(app)/populations/diabetes": [48,[2,3]],
		"/(auth)/(app)/populations/incapacities": [49,[2,3]],
		"/(auth)/(app)/practice": [50,[2,3]],
		"/(auth)/(app)/practice/documents/[uuid]": [51,[2,3]],
		"/(no-auth)/sentry/test": [62],
		"/(no-auth)/(background-hue)/welcome": [59,[10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';